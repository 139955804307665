import _ from "lodash";

const config = {
  all: {
    env: process.env.REACT_APP_STAGE || "PROD",
    baseName: process.env.PUBLIC_URL,
    sessionTimeout: 1000 * 5 * 12 * 20, //20 min
    showPopupTime: 1000 * 5 * 12 * 1, //1 min
  },
  DEV: {
    baseURL: `${window.location.protocol}//${window.location.hostname}:${window.location.port}`,
    apiBase:
      //"http://20.10.55.92:30062/api/partner-service/v1",
      "https://fpd-dev.incedopay.com/api/partner-service/v1"
  },
  UAT: {
    baseURL: `${window.location.protocol}//${window.location.hostname}:${window.location.port}`,
    apiBase:
      "https://partnerdirectory-uat.usbank.com/api/partner-service/v1",
  },
  PROD: {
    baseURL: `${window.location.protocol}//${window.location.hostname}:${window.location.port}`,
    apiBase:
      "https://partnerdirectory.usbank.com/api/partner-service/v1",
  }

};

export default _.merge(config.all, config[config.all.env]);
